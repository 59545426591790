<template>
  <div>
    <Card>
      <template #title>Отчёт на удаление №{{ report.id }}</template>
      <template #content>
        <p><strong>Дата создания: </strong> {{ formatDate(report.createdAt) }}</p>
        <p v-if="report.updatedAt !== null"><strong>Дата обновления: </strong>{{ formatDate(report.updatedAt) }}</p>
        <p v-if="report.completedAt !== null"><strong>Дата завершения: </strong> {{ formatDate(report.completedAt) }}</p>
        <Divider></Divider>
        <p><strong>Бюро: </strong> {{ $filters.translate($store, 'deleteReportBureaus', report.bureau) }}</p>
        <p><strong>Тип отчета: </strong> {{ $filters.translate($store, 'deleteReportTypes', report.type) }}</p>
        <p><strong>Статус: </strong> <StatusChip :value="report.status" compendium="processStatuses"/></p>
        <p><strong>Имя файла: </strong> {{ report.filename }}</p>
      </template>
      <template #footer>
        <div class="grid justify-content-between">
          <div>
            <SplitButton v-if="actions.length > 0" label="Действия c отчётом" icon="pi pi-bolt" :model="actions"></SplitButton>
          </div>
          <div>
            <Button @click="this.refresh" icon="pi pi-refresh" class="p-button-rounded p-button-outlined"></Button>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import StatusChip from "@/components/Common/StatusChip.vue";
import FilterDateService from "@/service/FilterDateService";
import DeleteReportActions from "@/helpers/actions/deleteReportActions";

export default {
  name: 'DeleteReportCard',
  components: {StatusChip},
  props: {
    report: {
      type: Object,
      required: true
    },
    onRefresh: {
      type: Function,
      default: () => null
    }
  },
  data() {
    return {
      actions: []
    }
  },
  mounted() {
    this.actions = this.generateItems(this.report)
  },
  methods: {
    generateItems(data) {
      const actions = new DeleteReportActions(data);
      actions.afterReset = this.refresh;
      actions.afterResend = this.refresh;
      return actions.getItems();
    },
    formatDate(date) {
      return FilterDateService.dateToString(date)
    },
    refresh() {
      if (this.onRefresh !== null) {
        this.onRefresh();
      }
    }
  }
}
</script>