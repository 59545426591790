<template>
  <div>
    <div class="flex justify-content-center" v-if="!notCenter">
      <div class="col-12 lg:col-6" style="position: absolute;top: 50%;transform: translate(0, -50%);">
        <Message severity="error" :closable="false">{{ message ? message : 'Ошибка' }}</Message>
      </div>
    </div>
    <div class="grid" v-if="notCenter">
      <div class="col-12 lg:col-6">
        <Message severity="error" :closable="false">{{ message ? message : 'Ошибка' }}</Message>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Error",
  props: {
    message: {
      type: String
    },
    notCenter: {
      type: Boolean
    }
  }
}
</script>

<style scoped>

</style>