<template>
  <div class="flex col-12 justify-content-center" v-if="loading" style="position: absolute;top: 50%;transform: translate(0, -50%);">
    <ProgressSpinner></ProgressSpinner>
  </div>
  <div v-if="!loading && report !== null">
    <div class="grid">
      <div class="col-12 lg:col-4">
        <DeleteReportCard
          :report="report"
          :on-refresh="this.getData"
        />
      </div>
      <div class="col-12 lg:col-8">
        <Card v-if="report.type === 'credit_history'">
          <template #title>Список удалённых заявок / займов</template>
          <template #content>
            <AbstractTable
              ref="deleteActionsTable"
              code="deleteActions"
              :default-data="{reportId: report.id}"
              display-filter="menu"
            />
          </template>
        </Card>
        <DeleteEventsTable
          v-if="report.type === 'event'"
          title="Список удаленных событий"
          :default-data="{reportId: report.id}"
        />
      </div>
    </div>
  </div>
  <div v-if="!loading && error !== null">
    <Error :message="error"/>
  </div>
</template>

<script>
import CRUDService from "@/service/CRUDService";
import ResponseService from "@/service/ResponseService";
import FilterDateService from "@/service/FilterDateService";
import DeleteReportCard from "@/components/Report/DeleteReportCard.vue";
import Error from "@/components/Error.vue";
import AbstractTable from "@/components/AbstractTable.vue";
import DeleteEventsTable from "@/components/tables/DeleteEventsTable.vue";

export default {
  name: "DeleteReport",
  components: {DeleteEventsTable, AbstractTable, Error, DeleteReportCard},
  data() {
    return {
      report: null,
      id: null,
      loading: true,
      error: null
    }
  },
  mounted() {
    this.id = parseInt(this.$router.currentRoute.value.params.id);
    this.getData()
  },
  methods: {
    formatDate(date) {
      return FilterDateService.dateToString(date)
    },
    getData() {
      this.loading = true;
      CRUDService.get('delete_report', this.id).then((res) => {
        if (typeof res === 'object' && res.id) {
            this.report = res
        } else {
          this.$toast.add({
            severity: 'warn',
            summary: 'Отчёт на удаление не найден',
            detail: 'Отчёт на удаление с id "' + this.id + '" не найден',
            life: 3000
          })
        }
      }).catch((err) => {
        ResponseService.handleErrorResponseWithoutForm(err, this.$toast)
        this.error = 'Произошла ошибка'
      })
      .finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>

</style>